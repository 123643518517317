<template>
  <div>
    <h3>Imprima a etiqueta ou registre a saída do visitante ou acompanhante</h3>
    <v-container fluid>
      <h4>Informações do Paciente</h4>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="patient_name"
            label="Nome do Paciente"
            class="font-weight-black"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="functional_unity"
            label="Unidade Funcional"
            class="font-weight-black"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="hospital_bed"
            label="Leito"
            class="font-weight-black"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="name"
            label="Nome"
            class="font-weight-black"
            disabled
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="social_name"
            class="font-weight-black"
            label="Nome Social"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="document_type"
            class="font-weight-black"
            label="Tipo de Documento"
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-text-field
            v-model="document_number"
            class="font-weight-black"
            label="Número do Documento"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="phone_number"
            label="Telefone"
            class="font-weight-black"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <!--  <v-container>
    <h4>Informações do Visitante ou Acompanhante</h4>
    <v-row>
        
        <v-col>
          <h5>Tipo de Permanências</h5>
        <v-radio-group v-model="radioGroup" class="float-left">
          <v-radio :key="visitor" :label="`Visitante`" :value="e" class="float-left"></v-radio>
        </v-radio-group>  
        <v-radio-group v-model="radioGroup" class="float-left">
          <v-radio :key="escort" :label="`Acompanhante`" :value="s" class="float-left"></v-radio>
        </v-radio-group>
        </v-col>

    </v-row>
    </v-container>
-->
      <v-container fluid>
        <v-row>
          <v-col cols="10" md="2">
            <v-text-field
              v-model="kinships"
              label="Parentesco"
              class="font-weight-black"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" :md="mdPermanence">
            <v-text-field
              v-model="permanence_time"
              label="Data de entrada"
              class="font-weight-black"
              disabled
            ></v-text-field>
          </v-col>
          <v-col v-if="final_permanence" cols="12" md="6">
            <v-text-field
              v-model="final_permanence"
              label="Data de saída"
              class="font-weight-black"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <h4>Observações:</h4>
          <v-col cols="12" sm="6" md="12">
            <v-text-field
              v-model="observation"
              label="Observações do visitante ou acompanhante"
              class="font-weight-black"
              outlined
              disabled
            ></v-text-field>
          </v-col>
          <h4>Vacina:</h4>
          <v-col cols="12" sm="6" md="12">
            <v-text-field
              v-model="vaccine_observation"
              label="Observações da vacina"
              class="font-weight-black"
              outlined
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="sticker_number"
              label="Número Etiqueta"
              class="font-weight-black"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="entry_date"
              class="font-weight-black"
              label="Data e Hora da Entrada"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="end" v-if="verify_depature">
          <v-btn
            class="float-end primary mr-2"
            type="button"
            dark
            color="success"
            @click="print()"
          >
            <v-icon size="22" class="icon">mdi-printer</v-icon>
            <span>Imprimir Etiqueta</span>
          </v-btn>

          <v-btn
            class="float-end primary"
            type="button"
            dark
            color="error"
            @click="checkout()"
          >
            <v-icon size="22" class="icon">mdi-exit-to-app</v-icon>
            <span>Registrar Saída</span>
          </v-btn>
        </v-row>

        <v-row>
          <v-btn
            class="float-start"
            type="button"
            dark
            color="dark"
            @click="backToList()"
          >
            <v-icon size="22" class="icon">mdi-arrow-left-thick </v-icon>
            <span>Voltar</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-container>

    <!-- Transformar em componente -->
    <div v-show="showTheCode" id="print-sticker">
      <div style="font-weight: bold; text-align: center">
        <p style="font-size: 9px">{{ hospital_name }}</p>
      </div>
      <div style="font-weight: bold; margin-top: 5px">
        <div>
          <span style="font-size: 8px"> {{ this.permanence_type }}: </span>
          <span style="font-size: 13px">{{
            social_name ? social_name : name
          }}</span>
        </div>
        <div>
          <span style="font-size: 8px"> ENTRADA:</span>
          <span style="font-size: 13px"> {{ entry_date }} </span>
        </div>
      </div>
      <div
        style="
          font-weight: bold;
          margin-top: 15px;
          margin-left: 2px;
          margin-bottom: 15x;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          padding: 5px;
        "
      >
        <div style="margin-top: 3px">
          <span style="font-size: 8px"> PACIENTE:</span>
          <span style="font-size: 13px"> {{ patient_name.trim() }} </span>
        </div>

        <div style="margin-top: 3px">
          <span style="font-size: 8px"> UNIDADE: </span>
          <span style="font-size: 13px">
            {{ functional_unity.toUpperCase() }}
          </span>
        </div>
        <div style="margin-top: 3px">
          <span style="font-size: 8px"> LEITO: </span>
          <span style="font-size: 13px">
            {{ hospital_bed.toUpperCase() }}
          </span>
          <span style="font-size: 25px; display: flex; justify-content: center">
            {{ this.permanence_type }}
          </span>
        </div>
      </div>

      <div style="margin-top: 5px; margin-left: 2px; font-weight: bold">
        <span style="font-size: 8px"> ETIQUETA: </span>
        <span style="font-size: 13px">{{ sticker_number }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "@/axios";
export default {
  data() {
    return {
      mdPermanence: 2,
      hospital_name: "",
      patient_name: "",
      permanence_type: "",
      functional_unity: "",
      hospital_bed: "",
      name: "",
      numberTag: "",
      social_name: "",
      document_type: "",
      document_number: "",
      phone_number: "",
      kinships: "",
      permanence_time: "",
      observation: "",
      vaccine_observation: "",
      sticker_number: "",
      entry_date: "",
      departure_date: "",
      verify_depature: true,
      showTheCode: false,
    };
  },
  mounted() {
    this.mountComponent(this.$route.query.item);
    this.verifyDepature();
    this.hospital_name = this.$store.state.authUser.unity_name;
  },

  methods: {
    getFirstAndLastNamePatientOrVisitor(name) {
      let name_splited = name.split(" ");

      if (name_splited.length == 1) {
        return name_splited[0].toUpperCase();
      }

      return (
        name_splited[0].toUpperCase() +
        " " +
        name_splited[name_splited.length - 1].toUpperCase()
      );
    },
    print() {
      this.$htmlToPaper("print-sticker");
    },
    backToList() {
      this.$router.push("/PesquisarMovi");
    },
    verifyDepature() {
      if (this.departure_date) {
        this.verify_depature = false;
      }
    },
    checkout() {
      const dados = new Object();
      dados.historicalMovementPersonId = this.$route.query.item.id;
      this.$swal({
        title: "Você tem certeza que deseja registrar saída?",
        text: "Esta ação não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/historical_movement_people/checkout`, dados)
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Registro de saída atualizado com sucesso!",
                showConfirmButton: false,
                timer: 3000,
              }).then(() => {
                this.$router.push({ path: "/PesquisarMovi" });
              });
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                title: "Ocorreu um erro!",
                showConfirmButton: false,
                timer: 3000,
              });
            });
        }
      });
    },
    mountComponent(data) {
      this.permanence_type = data.person[0].personType.person_type_name;
      this.patient_name = this.getFirstAndLastNamePatientOrVisitor(
        data.hospitalPatient[0].hospital_patient_name
      );
      this.functional_unity = data.hospitalPatient[0].functional_unity;
      this.hospital_bed = data.hospitalPatient[0].hospital_bed;
      this.name = this.getFirstAndLastNamePatientOrVisitor(
        data.person[0].name_person
      );
      this.observation = data.person[0].observation;
      this.vaccine_observation = data.person[0].vaccine_observation;
      this.social_name = data.person[0].social_name;
      this.document_type = data.person[0].documents.name_type_document;
      this.document_number = data.person[0].documents.document_number;
      this.phone_number = data.person[0].phones.phone_number;
      this.sticker_number = data.person[0].sticker.sticker_number;
      this.kinships = data.person[0].kinship.kinship_name;
      this.entry_date = data.created_at;
      this.permanence_time = data.permanence;
      this.final_permanence = data.final_permanence
        ? (this.final_permanence = data.final_permanence)
        : (this.final_permanence = false);
      if(this.final_permanence == false){
        this.mdPermanence = 6;
      }
      // this.final_permanence = data.final_permanence;
      this.departure_date = data.departure_date;
    },
  },
};
</script>

<style>
.print-sticker {
  margin-left: 5px !important;
  font-size: 10pt !important;
  font-weight: bold !important;
}

.elementos-pacientes {
  background-color: #000;
  display: flex;
  justify-content: space-around;
}
.elementos-visitantes {
  display: flex;
  justify-content: space-around;
}
</style>